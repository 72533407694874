import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable';
import App from './App';
import SaveGetData from "./utils/saveGetData";


const style = {
  display: 'flex',
  paddingTop: '30%',
  textAlgin: 'center',
  justifyContent: 'center'
}

const Login = Loadable({
  loader: () => import('./page/login'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Verify_email = Loadable({
  loader: () => import('./common/verify_email/index.jsx'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Sign = Loadable({
  loader: () => import('./page/sign'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Forgot = Loadable({
  loader: () => import('./page/forgot'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Main = Loadable({
  loader: () => import('./page/main/main'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Search = Loadable({
  loader: () => import('./common/search'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});

const RequestDetails = Loadable({
  loader: () => import('./page/request-details'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const ReportProblem = Loadable({
  loader: () => import('./page/reportProblem'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Notification = Loadable({
  loader: () => import('./page/notification'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Edit = Loadable({
  loader: () => import('./page/edit'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const UserName = Loadable({
  loader: () => import('./page/userName'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const UserCity = Loadable({
  loader: () => import('./page/userCity'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const UserIntroduction = Loadable({
  loader: () => import('./page/userIntroduction'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const UserCategory = Loadable({
  loader: () => import('./page/userCategory'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Favorites = Loadable({
  loader: () => import('./common/favorites'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Comment = Loadable({
  loader: () => import('./page/comment'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Account = Loadable({
  loader: () => import('./page/account'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Feedback = Loadable({
  loader: () => import('./page/feedback'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Product = Loadable({
  loader: () => import('./page/product'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});

const NoPage = Loadable({
  loader: () => import('./page/noPage'),

  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Rules = Loadable({
  loader: () => import('./page/rules'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const ShareLink = Loadable({
  loader: () => import('./page/shareLink'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const EditorsDetails = Loadable({
  loader: () => import('./page/editors-details'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const EditorsDetailsNew = Loadable({
  loader: () => import('./page/editors-details-new'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const ResetPassword = Loadable({
  loader: () => import('./page/resetPassword'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const CommentReply = Loadable({
  loader: () => import('./page/comment-reply'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
/*const Text = Loadable({
    loader: () => import('./page/text'),
    loading() {
        return <div style={style}>Loading...</div>
    }
});*/
const Verify = Loadable({
  loader: () => import('./page/verify'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const WhatVipon = Loadable({
  loader: () => import('./page/whatVipon'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const BannerPlan = Loadable({
  loader: () => import('./page/bannerPlan'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const GiveAway = Loadable({
  loader: () => import('./page/giveaway'),
  // loader: () => import('./page/warmUp'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const WarmUp = Loadable({
  loader: () => import('./page/warmUp'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const BlackFriday = Loadable({
  loader: () => import('./page/blackFriday'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const EpReport = Loadable({
  loader: () => import('./page/epReport'),
  // loader: () => import('./page/warmUp'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const DownLoad = Loadable({
  loader: () => import('./page/downLoad'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const ActivityDeals = Loadable({
  loader: () => import('./page/activity-deals'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const ImportantNotification = Loadable({
  loader: () => import('./page/importantNotification'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Sale = Loadable({
  loader: () => import('./page/sale'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const Tiktok = Loadable({
  loader: () => import('./page/tiktok'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const ProductReview = Loadable({
  loader: () => import('./page/productReview'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const advertisement = Loadable({
  loader: () => import('./page/ads'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const lottery = Loadable({
  loader: () => import('./page/lottery'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});

const black_5_wheel = Loadable({
  loader: () => import('./page/black_5_wheel'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});

const experts = Loadable({
  loader: () => import('./page/experts'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const withdrawal_records = Loadable({
  loader: () => import('./page/withdrawal-records'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const promote_influencer = Loadable({
  loader: () => import('./page/promote_influencer'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});
const maintain_upgrade = Loadable({
  loader: () => import('./page/maintain_upgrade'),
  loading() {
    return <div style={style}>Loading...</div>
  }
});

const PrivateRoute = ({ component: ComposedComponent, ...rest }) => {
  const Authentication = () => {
    let isLogin = SaveGetData.getData('token') ? true : false;
    return <Route {...rest}
      render={props => isLogin ? <ComposedComponent {...props} /> : <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location }
        }}
      />
      }
    />
  }

  return <Authentication />;
}

export default class Router extends Component {
  render() {
    return (
      <HashRouter>
        <App>
          <Switch>
            <Route path="/new_sign" component={Sign} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/login" component={Login} />
            <Route path="/Verify_email" component={Verify_email} />
            <Route path="/search/:id" component={Search} />
            <PrivateRoute path="/request-details/:id" component={RequestDetails} />
            <PrivateRoute path="/reportProblem/:id/:rw" component={ReportProblem} />
            <PrivateRoute path="/notification" component={Notification} />
            <PrivateRoute path="/edit/:id" component={Edit} />
            <PrivateRoute path="/userName" component={UserName} />
            <PrivateRoute path="/userCity" component={UserCity} />
            <PrivateRoute path="/userIntroduction" component={UserIntroduction} />
            <PrivateRoute path="/userCategory" component={UserCategory} />
            <PrivateRoute path="/favorites/:id" component={Favorites} />
            <PrivateRoute path="/comment" component={Comment} />
            <PrivateRoute path="/account" component={Account} />
            <PrivateRoute path="/feedback" component={Feedback} />
            <Route path="/rules" component={Rules} />
            <Route path="/product/:id" component={Product} />
            <Route path="/shareLink/:id" component={ShareLink} />
            <Route path="/editorsDetails/:id" component={EditorsDetails} />
            <Route path="/editorsDetailsNew/:id" component={EditorsDetailsNew} />
            <Route path="/resetPassword/:id" component={ResetPassword} />
            <Route path="/commentReply/:productId/:commentId" component={CommentReply} />
            <Route path="/verify/:token" component={Verify} />
            <Route path="/whatVipon" component={WhatVipon} />
            <Route path="/bannerPlan" component={BannerPlan} />
            <Route path="/giveaway" component={GiveAway} />
            {/* <Route path="/black-friday/pre" component={WarmUp}/>
            <Route path="/black-friday/index" component={BlackFriday}/> */}
            <Route path="/epReport/:id" component={EpReport} />
            <Route path="/ads" component={advertisement} />
            <Route path="/black_5_wheel" component={black_5_wheel} />
            <Route path="/experts" component={experts} />
            <Route path="/withdrawal_records" component={withdrawal_records} />
            <Route path="/promote_influencer" component={promote_influencer} />
            <Route path="/maintain-upgrade" component={maintain_upgrade} />
            <Route path="/downLoad" component={DownLoad} />
            <Route path="/importantNotification" component={ImportantNotification} />
            <Route path="/activity-deals/:id" component={ActivityDeals} />
            <Route path="/sale" component={Sale} />
            <Route path="/flashsale" component={Tiktok} />
            <Route path="/productReview/:id" component={ProductReview} />
            <Route path="/lottery" component={lottery} />
            <Route path="/" component={Main} />
            <Route component={NoPage} />
          </Switch>
        </App>
      </HashRouter>
    )
  }
}
